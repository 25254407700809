import './App.css';
import {Canvas} from "@react-three/fiber";
import {PerspectiveCamera} from "@react-three/drei";
import { Bloom, DepthOfField, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'
import Diamond from "./components/LoadDiamond/Diamon";


function App() {

  return (
    <div className="App">
      <Canvas 	id="main"
                 gl={{ powerPreference: 'high-performance', antialias: true, stencil: false, depth: false }}
                 onCreated={(state)=>{
                     state.gl.setClearColor("#262837")
                 }}>
          <EffectComposer>

              <DepthOfField focusDistance={0} focalLength={0.05} bokehScale={2} height={480} />
              <Bloom luminanceThreshold={1} intensity={0.5} levels={9} mipmapBlur />
              <Noise opacity={0.01} />
              <Vignette eskil={false} offset={0.1} darkness={1} />
          </EffectComposer>
          <Diamond rotation={[0.1, 0, 0]} position={[-4, 0.5, 0]} />
          <PerspectiveCamera makeDefault position={[-4, 0, 4]} fov={75}/>

      </Canvas>
    </div>
  );
}

export default App;
