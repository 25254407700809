import {useRef} from "react";
import {Caustics, CubeCamera, MeshRefractionMaterial} from "@react-three/drei";
import {useFrame, useLoader} from "@react-three/fiber";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

import {Text} from "troika-three-text";
import {RGBELoader} from "three/addons/loaders/RGBELoader";

function Diamond(props) {
const {scene} = props;
    console.log(scene)
    const myText = new Text();
    myText.text = 'Ответ'
    myText.fontSize = 0.4
    myText.position.z = 1.8;
    myText.name = 'answer';
    myText.position.x = -4.5;
    myText.rotation.x =0;
    myText.position.y = 0.1
    myText.color = 0x9966FF
    myText.sync()
   // scene.add(myText);
    const ref = useRef()
    let mesh = useLoader(OBJLoader,'/models/DIAMOND.obj');
    const texture = useLoader(RGBELoader, '/studio_small_09_2k.hdr')

    let answerRotate=false;

        const config = {
            bounces: 1,
            aberrationStrength: 0.01,
            ior: 2,
            fresnel: 1,
            color: 'white',
            fastChroma: true
        }
        let rotatespeed=0.1
    let rotate=true;
    useFrame((state)=>{


if(!answerRotate){
    if(rotate){
        ref.current.rotateY(0.001);
    }

}
if(answerRotate){
    ref.current.rotateY(rotatespeed);
    if(rotatespeed > 0.001){
        rotatespeed -= 0.001;
    } else {
        answerRotate = false;
        rotatespeed = 0.1;
        rotate=false;
        if(!state.scene.getObjectByName('answer')){
            state.scene.add(myText);
        }
        setTimeout(()=>{
            rotate = true;
            state.scene.remove(state.scene.getObjectByName("answer"));
        },2000)
    }
}

console.log(ref.current.rotation._y);
    })

    return (
        <CubeCamera resolution={256} frames={1} envMap={texture}>
            {(texture) => (
                <Caustics
                    backfaces
                    color={config.color}
                    position={[0, -0.5, 0]}
                    lightSource={[5, 5, -10]}
                    worldRadius={0.1}
                    ior={0}
                    backfaceIor={1.1}
                    intensity={0}>
                    <mesh onPointerDown={()=>{
                        if(rotate && !answerRotate) answerRotate = !answerRotate;
                    }}  ref={ref} geometry={mesh.children[0].geometry} {...props}>
                        <MeshRefractionMaterial envMap={texture }{...config} toneMapped={false} />
                    </mesh>
                </Caustics>
            )}
        </CubeCamera>
    )
}

export default Diamond;
